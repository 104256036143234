<template>
    <div class="client__info des-991">
        <ul class="client__info-content">
            <li class="block-name">
                <p class="cardClient__info-name">{{ fullName }}</p>
            </li>
            <li class="wrapper-info-1 info-item">
                <label class="cardClient-title">{{ $t('IIN') }}</label>
                <p class="cardClient-value">{{ user.iin }}</p>
            </li>
            <li class="wrapper-info-2 info-item">
                <label class="cardClient-title">{{ $t('Phone') }}</label>
                <p class="cardClient-value">{{ user.phone_number }}</p>
            </li>
            <li class="wrapper-info-3 info-item">
                <label class="cardClient-title">{{ $t('Birth date') }}
                    <div class="edit-icon" v-if="dateHelper.format(user.birth_date) === 'Invalid date'" @click="edit.birthDate = true"></div>
                </label>
                <div v-if="!edit.birthDate" class="text-wrapper">
                    <p class="cardClient-value max-line-1">{{ dateHelper.format(user.birth_date) }}</p>
                </div>
                <div v-if="edit.birthDate" class="input-wrapper" :class="{'hidden': !edit.birthDate}">
<!--                    <input type="text" class="input" :class="{'error': errors.birthDate}" v-model="user.birth_date">-->
                    <the-mask v-model="user.birth_date"
                              type="tel"
                              class="input birth-date-input"
                              :class="{'error': errors.birthDate}"
                              :masked="true"
                              :placeholder="$t('DMY')"
                              :mask="['##.##.####']"/>
                </div>
            </li>
            <li class="wrapper-info-4 info-item">
                <label class="cardClient-title">Email
                    <div class="edit-icon" @click="edit.email = true"></div>
                </label>
                <div v-if="!edit.email" class="text-wrapper">
                    <p class="cardClient-value max-line-1" :class="{'error': errors.email}">{{ user.email }}</p>
                </div>
                <div class="input-wrapper" :class="{'hidden': !edit.email}">
                    <input type="text" class="input" :class="{'error': errors.email}" v-model="user.email">
                    <span v-if="errors.email" class="error-text">{{ $t(errors.email) }}</span>
                </div>
            </li>
            <li class="wrapper-info-5 info-item">
                <label class="cardClient-title">{{ $t('Address') }}
                    <div class="edit-icon" @click="edit.address = true"></div>
                </label>
                <div v-if="!edit.address" class="text-wrapper">
                    <p class="cardClient-value max-line-2">{{ user.address }}</p>
                </div>
                <div class="input-wrapper" :class="{'hidden': !edit.address}">
                    <input type="text" class="input" v-model="user.address">
                </div>
            </li>
            <li class="block-link" v-if="true">
                <div>
                    <a
                        @click="onChatOperator"
                       class="client__info-link">{{ $t('Report an insurance case') }}</a>
                </div>
            </li>
            <li class="block-edit">
                <button v-if="edit.address || edit.email || edit.birthDate" @click="saveClientInfo" class="button">
                    {{ $t('Save changes') }}
                </button>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import {clientInfoMixin} from "@/mixins/clientInfoMixin";

export default {
    name: "ClientInfoDesktop",
    mixins: [clientInfoMixin],
    methods:{
        ...mapActions("newChat", ["toggleNewChat"]),
        onChatOperator  ()  {
            this.toggleNewChat();
            /*
            this.$store.dispatch('chat/startEmptyChat')
            */ // Старая реализация чата выплат
        },
    }
}
</script>

<style lang="scss" scoped>
.cardClient-value {
    font-weight: 400;
    font-size: 14px;
    line-height: 127.1%;
    color: #000000;

    &.max-line-1 {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -moz-box;
        -moz-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        line-clamp: 1;
        box-orient: vertical;
    }

    &.max-line-2 {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -moz-box;
        -moz-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-clamp: 2;
        box-orient: vertical;
    }
}

.cardClient-title {
    font-weight: 400;
    font-size: 11px;
    line-height: 127.1%;
    color: #60627B;
}

.cardClient__info-name {
    font-weight: 400;
    font-size: 18px;
    line-height: 127.1%;
}

.client__info {
    padding: 30px 0 18px;
    width: 100%;
    border-bottom: 1px solid rgba(172, 185, 207, 0.5);

    .client__info-content {
        display: grid;
        grid-template-columns: 153px 158px 243px 257px;
        grid-template-rows: auto auto auto;
        width: 100%;
        gap: 4px 10px;

        & > li {
            display: flex;
            flex-direction: column;
        }

        .info-item {
            min-height: 39px;
        }

        .input-wrapper,
        .text-wrapper {
            position: relative;
            width: fit-content;

            &.hidden {
                display: none;
            }

            .birth-date-input {
                width: 140px;
            }
        }

        .edit-icon {
            margin-left: 5px;
            display: inline-block;
            vertical-align: bottom;
            width: 13px;
            height: 15px;
            background: url('~@/assets/images/pencil.svg') center/ 9px 11px no-repeat;
            cursor: pointer;
        }

        .input {
            padding: 0 8px;
            width: 230px;
            height: 24px;
            border: 1px solid #D0D1E2;
            background-color: transparent;
            border-radius: 30px;
            font-weight: 400;
            font-size: 14px;
            transition: all .2s;
        }

        .block-name {
            grid-area: 1 / 1 / 2 / 5;
            height: 31px;
        }

        .wrapper-info-1 {
            grid-area: 2 / 1 / 3 / 2;
        }

        .wrapper-info-3 {
            grid-area: 2/ 2 / 3 / 3;
        }

        .wrapper-info-5 {
            grid-area: 2 / 3 / 3 / 4;
        }

        .wrapper-info-2 {
            grid-area: 3 / 1 / 4 / 2;
        }

        .wrapper-info-4 {
            grid-area: 3 / 2 / 4 / 3;
        }

        .wrapper-info-6 {
            grid-area: 3 / 3 / 4 / 4;
        }

        .block-link {
            grid-area: 1 / 4 / 3 / 5;
        }

        .block-edit {
            grid-area: 3 / 4 / 4 / 5;
            align-self: flex-end;
            width: fit-content;

            .button {
                margin-left: 20px;
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                color: $orange;
                background: transparent;
                transition: all .2s;

                &:hover {
                    color: $hoverOrange;
                }
            }
        }

        .client__info-link {
            margin-top: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #FCFCFC;
            box-shadow: 5px 10px 20px rgba(115, 117, 140, 0.15);
            border-radius: 50px;
            font-weight: 600;
            font-size: 14px;
            line-height: 127.1%;
            color: #515D75;
            transition: box-shadow .2s;
            padding: 10px;
            text-align: center;

            &:hover {
                cursor: pointer;
                color: #515D75;
                box-shadow: 5px 10px 20px rgba(172, 185, 207, 0.7);
            }
        }

        .error-text {
            color: red;
            font-size: 12px;
        }
    }
}
</style>
