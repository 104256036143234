import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import i18n from "@/plugins/i18n"
import httpServicePlugin from "@/plugins/axios";
import LockPage from "@/plugins/lockPage";
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import VueMeta from "vue-meta"
import VueEllipseProgress from 'vue-ellipse-progress'
import helpers from '@/plugins/helpers'
import VueQuillEditor from 'vue-quill-editor'
import Quill from 'quill'
import VueToast from 'vue-toastification'
import "vue-toastification/dist/index.css";

import 'nomad-lk-chat-module'

// import 'vue-toast-notification/dist/theme-sugar.css';
import vClickOutside from 'v-click-outside'


import quillTable from 'quill-table'
Quill.register(quillTable.TableCell);
Quill.register(quillTable.TableRow);
Quill.register(quillTable.Table);
Quill.register(quillTable.Contain);
Quill.register('modules/table',quillTable.TableModule)

import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css'
import {Vuelidate} from "vuelidate";

Vue.config.productionTip = false
Vue.use(vClickOutside)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueEllipseProgress)
Vue.use(httpServicePlugin)
Vue.use(VueMeta)
Vue.use(helpers)
Vue.use(LockPage)
Vue.use(VueToast, {
  transition: "Vue-Toastification__fade",
  maxToasts: 1,
})
Vue.use(Vuelidate)


import { registerComponents } from "@/components/common/registerComponents";
registerComponents(Vue)

var maxRows = 9;
var maxCols = 5;
var tableOptions = [];
for (var r = 1; r <= maxRows; r++) {
    for (var c = 1; c <= maxCols; c++) {
        tableOptions.push('newtable_' + r + '_' + c);
    }
}

var toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],

    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction

    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],

    ['clean']     ,// remove formatting button
    [{ table: tableOptions }, { table: 'append-row' }, { table: 'append-col' }],
];
Vue.use(VueQuillEditor,  {
    theme: 'snow',
    modules: {
        toolbar:toolbarOptions,
        table: true,  // disable table module
    }
} )

// For debug and analyze performance
// import {lifecycleLoggerMixin} from "@/mixins/hooks/lifecycleLoggerMixin";
// Vue.mixin(lifecycleLoggerMixin)

/* eslint-disable */

new Vue({
    i18n,
    store,
    router,
    render: h => h(App),
    mounted() {
      $('#preloader-mobile-app').hide()
    }
}).$mount('#app')
