export default {
  namespaced: true,
  state: {
    isNewChatOpen: false,
    unreadMessagesCount: 0,
  },
  mutations: {
    SET_NEW_CHAT_OPEN(state, isOpen) {
      state.isNewChatOpen = isOpen;
    },
    SET_UNREAD_MESSAGES_COUNT(state, unreadMessagesCount) {
      state.unreadMessagesCount = unreadMessagesCount;
    }
  },
  actions: {
    toggleNewChat({ commit, state }) {
      commit("SET_NEW_CHAT_OPEN", !state.isNewChatOpen);
    },
    openNewChatModal({ commit }) {
      commit("SET_NEW_CHAT_OPEN", true);
    },
    closeNewChatModal({ commit }) {
      commit("SET_NEW_CHAT_OPEN", false);
    },
  },
};
