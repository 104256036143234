<template>
  <div>
    <vue-bottom-sheet-vue2  ref = "myBottomSheet"
                            :z-index = "9999999999"
                            class = "sos-modal-new"
                            overlay-color = "rgba(26, 26, 26, .4)">
      <div class = "sos-link-list">
        <SosMenuLink  :linkTitle="$t('MENU_CHAT_OPERATOR')"
                      :linkText = "$t('MENU_REPORT_INSURED_EVENT')"
                      linkIcon = "chat-icon"
                      @click.native = "onChatOperator"></SosMenuLink>
<!--        <SosMenuLink  v-if = "euroProtocolUser"-->
<!--                      :linkTitle = "$t('MENU_EUROPROTOCOL')"-->
<!--                      :linkText = "$t('EURO_LINK_TEXT')"-->
<!--                      linkIcon = "warning-icon"-->
<!--                      @click.native = "onEuroprotocol"> </SosMenuLink>-->
        <SosMenuLink  :linkTitle="$t('MENU_CHAT_WHATSAPP')"
                      :linkText = "$t('MENU_PURCHASING_QUESTIONS')"
                      linkIcon = "whatsapp-icon"
                      @click.native = "onChatWhatsapp"></SosMenuLink>
        <SosMenuLink  :linkTitle="$t('Call to Call-Center')"
                      linkIcon = "call-icon"
                      @click.native = "onCallCenter"></SosMenuLink>
      </div>
    </vue-bottom-sheet-vue2>
  </div>
</template>

<script>
import VueBottomSheetVue2 from '@webzlodimir/vue-bottom-sheet-vue2'
import SosMenuLink from '@/components/mobileApp/SosMenuLink'
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'SosModalNew',
  components: {
    VueBottomSheetVue2,
    SosMenuLink
  },
  computed: {
    ...mapGetters('auth', ['user']),
    euroProtocolUser () {
      // if (this.user !== undefined) {
      //   if (this.user.is_tester) {
      //     return true
      //   }
      // }
      return true
    }
  },
  methods: {
    onChatOperator  ()  {
      this.sosModalclose();
      this.$store.dispatch('newChat/openNewChatModal')
    },
    onInsuranceCase () {
      this.sosModalclose()
      this.$bvModal.show('insurance-case-report-modal')
    },
    onCallCenter () {
      this.sosModalclose()
      window.open('tel:2233', '_blank')
    },
    onChatWhatsapp () {
      this.sosModalclose()
      window.open('https://wa.me/77010330376', '_blank')
    },
    onEuroprotocol () {
      this.sosModalclose()
      this.$router.push({ name: 'EuroprotocolPage' })
    },
    sosModalOpen () {
      this.$refs.myBottomSheet.open()
    },
    sosModalclose () {
      this.$refs.myBottomSheet.close()
    },
    ...mapActions('chat',['startEmptyChat']),
  }
}
</script>

<style lang = "scss">

.sos-modal-new {
    .bottom-sheet__main {
        padding: 16px 16px calc(env(safe-area-inset-bottom) + 24px);
    }

    div.bottom-sheet__content {
        border-radius: 8px 8px 0 0;
    }

    div.bottom-sheet__draggable-area {
        padding: 8px 8px 4px;
    }

    .bottom-sheet__draggable-thumb {
        background: #EAEAEA !important;
    }
}

.sos-link-list {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}
</style>
