<template>
    <nomad-chat
        v-if="isWebComponentReady"
        ref="chatRef"
        :class="[isNewChatOpen && 'new-chat__open']"
    >
    </nomad-chat>
</template>

<script>
import {mapActions, mapMutations} from "vuex";
import Cookies from "js-cookie";

export default {
    data() {
        return {
            isWebComponentReady: false,
            elementsClassesToHideList: [
                'header-lk',
                'grecaptcha-badge',
                'ws-chat',
                'tab-content',
                'fixed-mob-button',
            ],
            elementsClassesToFixPositionList: [
                'ws-chat',
            ],
        };
    },
    computed: {
        isNewChatOpen() {
            return this.$store.state.newChat.isNewChatOpen;
        },
        locale() {
            return this.$i18n.locale
        },
        user() {
            return this.$store.state.auth.user
        },
        token() {
            return this.$store.state.auth.chatToken;
        }
    },
    watch: {
        isNewChatOpen: {
            immediate: true,
            handler (newVal) {
                newVal ? this.openChatModal() : this.closeChatModal
            }
        },
        locale: {
            immediate: true,
            handler (newVal) {
                this.$refs.chatRef?.setAttribute("locale", newVal.toLowerCase());
            }
        },
        token: {
            immediate: true,
            handler (newVal) {
               this.updateToken(newVal)
            }
        }
    },
    methods: {
        ...mapActions("newChat", ["closeNewChatModal"]),
        ...mapMutations("newChat", ["SET_UNREAD_MESSAGES_COUNT"]),
        ...mapMutations("auth", ["SET_CHAT_TOKEN"]),
        openChatModal() {
              this.setToken()
              this.$refs.chatRef?.setAttribute("is-open", 'open');
              this.updateLocale()
              this.$refs.chatRef?.addEventListener("chat-close", this.closeChatModal);
              this.hideNonChatElements();
            },
        setEnvironment() {
/*
            const currentEnvironment = process.env.NODE_ENV ?? 'production';
*/ // TODO необходимо понять, почему при билде deploy_dev скриптом у них идет building for production, и убрать заплатку ниже

            this.$refs.chatRef?.setAttribute("environment", this.getEnvironmentByOrigin());
        },
        closeChatModal() {
            this.$refs.chatRef?.setAttribute("is-open", '');
            this.$refs.chatRef?.removeEventListener("chat-close", this.closeChatModal)
            this.closeNewChatModal();
            this.showNonChatElements();
        },
        hideNonChatElements() {
            document.documentElement.classList.add('hide-elements-under-new-chat')
        },
        showNonChatElements() {
            document.documentElement.classList.remove('hide-elements-under-new-chat')
        },
        updateLocale() {
            this.$refs.chatRef?.setAttribute("locale", this.$i18n.locale)
        },
        setToken() {
            const token = Cookies.get('chat_token')
            this.$refs.chatRef?.setAttribute("token", token ?? '')
            this.SET_CHAT_TOKEN(token ?? '')
        },
        updateUnreadCounter(event) {
            this.SET_UNREAD_MESSAGES_COUNT(Number(event.detail) ?? 0)
        },
        updateToken(event) {
            this.$refs.chatRef?.setAttribute("token", event ?? '')
        },
        onRefreshToken(event) {
            Cookies.set('chat_token', event.detail, {expires: 365})
            this.SET_CHAT_TOKEN(event.detail)
        },
        getEnvironmentByOrigin() {//TODO убрать это, как только проблема с 'при билде deploy_dev скриптом у них идет building for production' решится
            const origin = window.location.origin;
            if (origin.includes('dev')) {
                return 'staging';
            } else {
                return 'production';
            }
        }
      },
    mounted() {
        this.isWebComponentReady = true;
        this.$nextTick(() => {
            this.setEnvironment()
            this.setToken()
            this.$refs.chatRef?.addEventListener("update-unread", this.updateUnreadCounter);
            this.$refs.chatRef?.addEventListener("update-token", this.onRefreshToken);
        })
    },
    beforeDestroy() {
        this.$refs.chatRef?.removeEventListener("update-unread", this.updateUnreadCounter);
        this.$refs.chatRef?.removeEventListener("chat-close", this.closeChatModal);
        this.$refs.chatRef?.removeEventListener("update-token", this.onRefreshToken);
    }
};
</script>
