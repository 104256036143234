<template>
    <div v-show="isVisibleTabBar">
        <SosButton @onSosClicked="onSosClicked"></SosButton>
        <BottomNavigation
            :options="options"
            v-model="selected"
            :titleColor="orangeColor"
            :borderColor="orangeColor"
            :iconColor="orangeColor"
        />
        <SosModal></SosModal>
        <SosModalBottomSheet
            ref="sosModalNew"
        ></SosModalBottomSheet>
    </div>
</template>

<script>
import BottomNavigation from "@/components/mobileApp/TabBar/BottomNavigation";
import SosButton from "@/components/mobileApp/TabBar/SosButton";
import SosModal from "@/components/mobileApp/modals/SosModal";
import SosModalBottomSheet from "@/components/mobileApp/modals/SosModalBottomSheet";

export default {
    name: "AppTabBar",
    components: {
        BottomNavigation,
        SosButton,
        SosModal,
        SosModalBottomSheet,
    },
    data: function () {
        return {
            selected: 1,
            options: [
                {
                    id: 1,
                    icon: 'nui-icon nui-icon-person-with-circle',
                    title: 'Кабинет',
                    path: {
                        name: 'dashboard'
                    },
                },
                {
                    id: 2,
                    icon: 'nui-icon nui-icon-buy',
                    title: this.$t('BUY'),
                    path: {
                        name: 'Comfort'
                    }
                },
                {
                    id: 3,
                    icon: '',
                    title: '',
                },
                {
                    id: 4,
                    icon: 'nui-icon nui-icon-call-with-circle',
                    title: '2233',
                    link: {
                        url: 'tel:2233',
                        target: '_blank'
                    }
                },
                {
                    id: 5,
                    icon: 'nui-icon nui-icon-more',
                    title: this.$t('Info'),
                    path: {
                        name: 'MorePage'
                    }
                }
            ],
            orangeColor: '#EF7F22',
            isVisibleTabBar: true
        }
    },
    methods: {
        onSosClicked() {
            this.$refs.sosModalNew.sosModalOpen()
        }
    },
    mounted() {
        this.$root.$on('hideTabBar', () => {
            this.isVisibleTabBar = false
        })
        this.$root.$on('showTabBar', () => {
            this.isVisibleTabBar = true
        })
    }
}
</script>

<style lang="scss">

</style>
